import 'bootstrap';
import AOS from 'aos';
import Rellax from 'rellax';
import Swiper, { Navigation, Pagination } from 'swiper';
import fitty from 'fitty';
//import _, { map } from './lib/underscore-esm-min.js';
import './lib/jvectormap';
import './lib/jvectormap-us-mill-en.js';
import './lib/jvectormap-ca-mill-en.js';
import './lib/jvectormap-na-mill-en.js';
import './lib/jvectormap-mx-milll-en.js';
import './lib/jvectormap-world-merc.js';
import './components/facilities.js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'


export const Global = class {

	/**
	 * Setup Main Navigation
	 */
	mainNavigation() {
		// console.log("this is the main navigation");
	}

	initCarousels() {
		Swiper.use([Navigation, Pagination]);
		let carousels = document.querySelectorAll('.swiper');

		carousels.forEach(carousel => {

			const swiperID = carousel.getAttribute('data-swiper-id');
			const swiper = new Swiper('.swiper[data-swiper-id="' + swiperID + '"]', {

				direction: 'horizontal',
				loop: true,

				pagination: {
					el: '.swiper-pagination',
				},

				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},

				scrollbar: {
					el: '.swiper-scrollbar',
				},
				autoplay: {
					delay: 5000,
				},
			});
		});
	}

	initVideoLanding() {

		const sticky = document.querySelector('.sticky');
		const videoWrapper = document.querySelector('.js-video-wrapper');

		if (sticky && videoWrapper) {

			document.addEventListener(
				'scroll',
				(event) => {

					const headingRect = sticky.getBoundingClientRect();
					const videoRect = videoWrapper.getBoundingClientRect();

					// if sticky heading top is greater than video top apply bottomed out
					if (headingRect.top > videoRect.top) {
						sticky.classList.add("bottomed-out");
					} else {
						sticky.classList.remove("bottomed-out");
					}
				},
				{ passive: true }
			);

		}
	}

	initFitty() {
		fitty('.fit-text');

		setTimeout(function () {
			fitty.fitAll();
		}, 250);
	}


	

	initInstrumentation() {
		let body = document.querySelector("body[data-instrumentation-key]");

		if (body != null) {
			window.appInsights = new ApplicationInsights({
				config: {
					instrumentationKey: body.dataset.instrumentationKey
				}
			});

			if (this.getCookie(".AspNet.Consent") == "yes") {
				window.appInsights.loadAppInsights();
				window.appInsights.trackPageView();
			}
		}
	}

	initCookieConsent() {
		var cookieConsentButton = document.getElementById("show-cookie-consent");
		let context = this;
		if (cookieConsentButton != null) {
			cookieConsentButton.addEventListener("click", function (event) {
				context.showCookieConsent();
			}, false);
		}

		var acceptButton = document.querySelector("#cookieConsent .accept-policy");
		if (acceptButton != null) {
			acceptButton.addEventListener("click", function (event) {
				document.cookie = acceptButton.dataset.cookieString;
				//deletes all existing cookies
				context.deleteAllCustomCookies();
				//adds custom cookies
				context.addCustomCookies(acceptButton.dataset.cookieString, "yes");
				context.hideCookieConsent();
				//add analytics and telemetry
				context.addAnalytics();
				window.appInsights.loadAppInsights();
				window.appInsights.trackPageView();

			}, false);
		}

		var declinetButton = document.querySelector("#cookieConsent .decline-policy");
		if (declinetButton != null) {
			declinetButton.addEventListener("click", function (event) {
				document.cookie = declinetButton.dataset.cookieString;
				//deletes all existing cookies
				context.deleteAllCustomCookies();
				//add custom cookies
				context.addCustomCookies(declinetButton.dataset.cookieString, "no");
				context.hideCookieConsent();
				//remove analytics and telemetry
				context.removeAnalytics();
			}, false);
		}
	}

	showCookieConsent() {
		var cookieConsent = document.querySelector("#cookieConsent");
		cookieConsent.classList.add("show");
	}

	hideCookieConsent() {
		var cookieConsent = document.querySelector("#cookieConsent");
		cookieConsent.classList.remove("show");
	}

	deleteAllCustomCookies() {
		var cookies = document.cookie.split(";");

		for (var i = 0; i < cookies.length; i++) {
			var cookie = cookies[i];
			var eqPos = cookie.indexOf("=");
			var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

			var notToDelete = ['ASP.NET_SessionId', 'XSRF-TOKEN', 'XSRF-V', '__RequestVerificationToken', '__zlcmid', 'UMB*', 'UMB_UCONTEXT', 'UMB_UCONTEXT_C', 'UMB_UPDCHK', 'UMB-XSRF-V', 'UMB-XSRF-TOKEN',
				'UMB_PREVIEW', 'UMB-WEBSITE-PREVIEW-ACCEPT', 'umb_installId', 'TwoFactorRememberBrowser','UMB_SESSION', '.AspNet.Consent'];

			//excludes umbraco cookies
			if (!notToDelete.includes(name)) {
				this.eraseCookie(name);
			}
		}
	}

	initAnalytics() {
		//add default denied consent
		var gTagDefaultConsent = document.createElement("script");
		gTagDefaultConsent.innerHTML = "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('consent', 'default', {'ad_storage': 'denied','analytics_storage': 'denied'});";
		document.head.appendChild(gTagDefaultConsent);

		var gTagScript = document.createElement("script");
		gTagScript.async = 1;
		gTagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-893JLV4YRM';
		document.head.appendChild(gTagScript);

		var gTagFunctionality = document.createElement("script");
		gTagFunctionality.innerHTML = "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-893JLV4YRM');";
		document.head.appendChild(gTagFunctionality);

		if (this.getCookie(".AspNet.Consent") == "yes") {
			this.addAnalytics();
		}
		else {
			this.removeAnalytics();
		}
	}

	addAnalytics() {
		//Add consent for gtag
		var gTagAcceptConsent = document.createElement("script");
		gTagAcceptConsent.innerHTML = "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('consent', 'update', {'ad_storage': 'granted','analytics_storage': 'granted'});";
		document.head.appendChild(gTagAcceptConsent);

	}

	removeAnalytics() {
		//deny consent
		var gTagDenyConsent = document.createElement("script");
		gTagDenyConsent.innerHTML = "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('consent', 'update', {'ad_storage': 'denied','analytics_storage': 'denied'});";
		document.head.appendChild(gTagDenyConsent);
	}

	getStringBetween(str, start, end) {
	const result = str.match(new RegExp(start + "(.*)" + end));
	return result[1];
}

	addCustomCookies(cookieString, consent) {

		document.cookie = cookieString;
		/*let domain = this.getStringBetween(cookieString, 'domain=', 'path=');*/
		let domain = "";

		if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
			domain = "localhost";
		}
		else {
			domain = ".andersonsinc.com";
		}
		this.createCookieConsent('cky-consent', consent, 30,domain);
		this.createCookieConsent('cookie' + consent + '-advertisement', consent, 30, domain);
		this.createCookieConsent('cookie' + consent + '-analytics', consent, 30, domain);
		this.createCookieConsent('cookie' + consent + '-functional', consent, 30, domain);
		this.createCookieConsent('cookie' + consent + '-performance', consent, 30, domain);
		this.createCookieConsent('cookie' + consent + '-necessary', consent, 30, domain);
	}

	getCookie(name) {
		let re = new RegExp(name + "=([^;]+)");
		let value = re.exec(document.cookie);
		return (value != null) ? unescape(value[1]) : null;
	}

	createCookieConsent(name, value, days, domain) {
		var expires = "";
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = "; expires=" + date.toGMTString();
		}
		else  expires = "";
		document.cookie = name + "=" + value + expires + "; path=/; domain= " + domain + "; secure; SameSite=none;";
	}

	readCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	}

	eraseCookie(name) {
		this.createCookieConsent(name, "", -1,"");
	}

	handleWidows() {
		var nbspSelectors = [
			'h1',
			'h2',
			'h3',
			'h4',
			'h5',
			'h6',
			'p',
			'blockquote'
		];

		var nbspWordMin = 6;

		nbspSelectors.forEach(function (selector) {
			var nbspElements = document.querySelectorAll(selector);
			nbspElements.forEach(function (element) {
				var wordCount = (element.innerHTML.split(" ").length);
				if (wordCount >= nbspWordMin) {
					element.innerHTML = element.innerHTML.replace(/ ([^ ]*)$/, '&nbsp;$1');
				}
			});
		});
	}

	initAccordions() {

		let accordions = document.querySelectorAll('.accordion-item');

		accordions.forEach(accordion => {
			accordion.addEventListener("click", function (event) {
				let target = accordion.parentElement;
				if (accordion.previousElementSibling != null) {
					accordion.previousElementSibling;
				}

				target.scrollIntoView();

			})
		});
	}

	initDownloadButtons() {
		let downloadButtons = document.querySelectorAll(".download-button");
		downloadButtons.forEach(element => {
			element.addEventListener("click", function (e) {
				e.preventDefault();
				var url = e.target.dataset.link;
				var filename = e.target.dataset.name;
				var target = e.target.dataset.target;
				var domain = window.location.origin;
				//hardfix for CDN Url rewrite
				if (url.includes("andecdn.")) {
					var URLValue = url.split('/media');
					url = domain + "/media" + URLValue[1];
				}
				var headers = new Headers();
				headers.append('Access-Control-Allow-Origin', domain);
				headers.append('Access-Control-Allow-Credentials', 'true');

				fetch(url, {
					method: 'GET',
					crossorigin: true,
					headers: headers
				})
					.then(res => res.blob())
					.then(res => {
						const aElement = document.createElement('a');
						aElement.setAttribute('download', filename);
						const href = URL.createObjectURL(res);
						aElement.href = href;
						aElement.style = "display: none";
						document.body.appendChild(aElement);
						aElement.click();
						URL.revokeObjectURL(href);
					});

			});
		});
	}

	/**
	 * Container Initialization
	 */
	init() {

		this.mainNavigation();
		AOS.init();
		this.initCarousels();
		this.initVideoLanding();
		this.initFitty();
		this.initInstrumentation();
		this.initAnalytics();
		this.initCookieConsent();
		this.handleWidows();
		this.initAccordions();
		this.initDownloadButtons();

		var hasRellax = document.getElementsByClassName('rellax');
		if (hasRellax.length > 0) {
			var rellax = new Rellax('.rellax');
			window.addEventListener('load', function () {
				rellax.refresh();
			})
		}

	
	}
};

const global = new Global();
global.init();